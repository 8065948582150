'use strict';

$(document).ready(function () {
    $('.menu__button').click(function () {
        $('.menu-nav').toggleClass('open');
        $('.header__link').toggleClass('hidden');
        $('.main-block img').toggleClass('hidden');
        $('.main-block__text').toggleClass('hidden');
        $('.header__contact').toggleClass('hidden');
        $('.header__adress').toggleClass('hidden');
    });
    $('.menu__close').click(function () {
        $('.menu-nav').removeClass('open');
        $('.header__link').removeClass('hidden');
        $('.main-block img').removeClass('hidden');
        $('.main-block__text').removeClass('hidden');
        $('.header__contact').removeClass('hidden');
        $('.header__adress').removeClass('hidden');
    });
    $('.menu-nav__about-link').click(function () {
        $('.menu-nav').removeClass('open');
        $('.header__link').removeClass('hidden');
        $('.main-block img').removeClass('hidden');
        $('.main-block__text').removeClass('hidden');
        $('.header__contact').removeClass('hidden');
        $('.header__adress').removeClass('hidden');
    });

    $('.header__contact_call').click(function () {
        $('.callback').addClass('is-visible');
        $('.callback__wrap').addClass('is-visible');
    });
    $('.menu__chat').click(function () {
        $('.chat').addClass('is-visible');
        $('.chat__wrap').addClass('is-visible');
    });
    $('.menu__proposal').click(function () {
        $('.proposal').addClass('is-visible');
        $('.proposal__wrap').addClass('is-visible');
    });
    $('.menu-nav__proposal-link').click(function () {
        $('.proposal').addClass('is-visible');
        $('.proposal__wrap').addClass('is-visible');
    });

    $('.footer__about_link').click(function () {
        $('.callback').addClass('is-visible');
        $('.callback__wrap').addClass('is-visible');
    });

    $('.block__link-1').click(function () {
        $('.course').addClass('is-visible');
        $('.course__wrap').addClass('is-visible');
    });

    $('.block__link-2').click(function () {
        $('.course').addClass('is-visible');
        $('.course__wrap').addClass('is-visible');
    });

    $('.callback__close').click(function () {
        $('.callback').removeClass('is-visible');
        $('.callback__wrap').removeClass('is-visible');
    });
    $('.callback__close').click(function () {
        $('.chat').removeClass('is-visible');
        $('.chat__wrap').removeClass('is-visible');
    });
    $('.callback__close').click(function () {
        $('.proposal').removeClass('is-visible');
        $('.proposal__wrap').removeClass('is-visible');
    });
    $('.callback__close').click(function () {
        $('.course').removeClass('is-visible');
        $('.course__wrap').removeClass('is-visible');
    });

    $('.benefit__block-1').hover(function () {
        $('.benefit__wrap_title-1').toggleClass('active-title');
    });
    $('.benefit__block-2').hover(function () {
        $('.benefit__wrap_title-2').toggleClass('active-title');
    });
    $('.benefit__block-3').hover(function () {
        $('.benefit__wrap_title-3').toggleClass('active-title');
    });
    $('.benefit__block-4').hover(function () {
        $('.benefit__wrap_title-4').toggleClass('active-title');
    });
    $('.benefit__block-5').hover(function () {
        $('.benefit__wrap_title-5').toggleClass('active-title');
    });
    $('.benefit__block-6').hover(function () {
        $('.benefit__wrap_title-6').toggleClass('active-title');
    });

    $('.footer__question-1 h4').click(function () {
        $('.footer__question-1 p').toggle('normal');
    });
    $('.footer__question-2 h4').click(function () {
        $('.footer__question-2 p').toggle('normal');
    });
    $('.footer__question-3 h4').click(function () {
        $('.footer__question-3 p').toggle('normal');
    });
    $('.footer__question-4 h4').click(function () {
        $('.footer__question-4 p').toggle('normal');
    });
    $('.footer__question-5 h4').click(function () {
        $('.footer__question-5 p').toggle('normal');
    });
    $('.footer__question-6 h4').click(function () {
        $('.footer__question-6 p').toggle('normal');
    });
    $('.footer__question-7 h4').click(function () {
        $('.footer__question-7 p').toggle('normal');
    });
    $('.footer__question-8 h4').click(function () {
        $('.footer__question-8 p').toggle('normal');
    });

    if ($('.footer__question-1').hasClass('is-open')) {
        $('.footer__question-1 p').css({
            'display': 'block'
        });
    } else {
        $('.footer__question-1 p').css({
            'display': 'none'
        });
    }

    $('.menu-1').hover(function () {
        $('.menu-nav__course').removeClass('is-hide');
        $('.course__link').addClass('icon-active');
    }, function () {
        $('.menu-nav__course').addClass('is-hide');
        $('.course__link').removeClass('icon-active');
    });

    $('.menu-2').hover(function () {
        $('.menu-nav__about').removeClass('is-hide');
        $('.about__link').addClass('icon-active');
    }, function () {
        $('.menu-nav__about').addClass('is-hide');
        $('.about__link').removeClass('icon-active');
    });

    $('.menu-3').hover(function () {
        $('.menu-nav__proposal').removeClass('is-hide');
        $('.proposal__link').addClass('icon-active');
    }, function () {
        $('.menu-nav__proposal').addClass('is-hide');
        $('.proposal__link').removeClass('icon-active');
    });

    $('#video-1').click(function () {
        $('.video__wrap-1').addClass('video__open');
    });
    $('.work__image-1 p').click(function () {
        $('.video__wrap-1').addClass('video__open');
    });
    $('.video__close').click(function () {
        $('.video__wrap-1').removeClass('video__open');
    });

    $('#video-2').click(function () {
        $('.video__wrap-2').addClass('video__open');
    });
    $('.work__image-2 p').click(function () {
        $('.video__wrap-2').addClass('video__open');
    });
    $('.video__close').click(function () {
        $('.video__wrap-2').removeClass('video__open');
    });

    $('#video-3').click(function () {
        $('.video__wrap-3').addClass('video__open');
    });
    $('.work__image-3 p').click(function () {
        $('.video__wrap-3').addClass('video__open');
    });
    $('.video__close').click(function () {
        $('.video__wrap-3').removeClass('video__open');
    });

    $('#video-4').click(function () {
        $('.video__wrap-4').addClass('video__open');
    });
    $('.work__image-4 p').click(function () {
        $('.video__wrap-4').addClass('video__open');
    });
    $('.video__close').click(function () {
        $('.video__wrap-4').removeClass('video__open');
    });

    $('a[href^="#navigation"]').click(function () {
        var anchor = $(this).attr('href');

        $('html, body').animate({
            scrollTop: $(anchor).offset().top
        }, 800);
    });
    $('a[href^="#feedback"]').click(function () {
        var anchor = $(this).attr('href');

        $('html, body').animate({
            scrollTop: $(anchor).offset().top
        }, 800);
    });
    $('a[href^="#course"]').click(function () {
        var anchor = $(this).attr('href');

        $('html, body').animate({
            scrollTop: $(anchor).offset().top
        }, 800);
    });
    $('a[href^="#training"]').click(function () {
        var anchor = $(this).attr('href');

        $('html, body').animate({
            scrollTop: $(anchor).offset().top
        }, 800);
    });
    $('a[href^="#progress"]').click(function () {
        var anchor = $(this).attr('href');

        $('html, body').animate({
            scrollTop: $(anchor).offset().top
        }, 800);
    });
    $('a[href^="#disciple"]').click(function () {
        var anchor = $(this).attr('href');

        $('html, body').animate({
            scrollTop: $(anchor).offset().top
        }, 800);
    });
    $('a[href^="#question"]').click(function () {
        var anchor = $(this).attr('href');

        $('html, body').animate({
            scrollTop: $(anchor).offset().top
        }, 800);
    });
    $('a[href^="#microblading"]').click(function () {
        var anchor = $(this).attr('href');

        $('html, body').animate({
            scrollTop: $(anchor).offset().top
        }, 800);
    });
    $('a[href^="#powdered"]').click(function () {
        var anchor = $(this).attr('href');

        $('html, body').animate({
            scrollTop: $(anchor).offset().top
        }, 800);
    });
    $('a[href^="#mixed"]').click(function () {
        var anchor = $(this).attr('href');

        $('html, body').animate({
            scrollTop: $(anchor).offset().top
        }, 800);
    });
    $('a[href^="#lip"]').click(function () {
        var anchor = $(this).attr('href');

        $('html, body').animate({
            scrollTop: $(anchor).offset().top
        }, 800);
    });
    $('a[href^="#correction"]').click(function () {
        var anchor = $(this).attr('href');

        $('html, body').animate({
            scrollTop: $(anchor).offset().top
        }, 800);
    });
    $('a[href^="#nav"]').click(function () {
        var anchor = $(this).attr('href');

        $('html, body').animate({
            scrollTop: $(anchor).offset().top
        }, 800);
    });
    $('a[href^="#form"]').click(function () {
        var anchor = $(this).attr('href');

        $('html, body').animate({
            scrollTop: $(anchor).offset().top
        }, 800);
    });

    $(window).scroll(function () {
        if ($(window).scrollTop() > $('.main-block').height() / 2) {
            $('.menu__close').addClass('menu__close_active');
            $('.menu').addClass('menu-white');
            $('.menu__span-1').addClass('black-span');
            $('.menu__span-2').addClass('black-span');
            $('.menu__span-3').addClass('black-span');
            $('.menu__span-4').addClass('black-span');
            $('.menu__span-5').addClass('black-span');
            $('.menu__span-6').addClass('black-span');
            $('.menu-nav').css({
                'background': '#fff'
            });
            $('.menu-nav__link').addClass('menu-nav__link-black');
            $('.menu-nav__course a').addClass('menu-nav__black');
            $('.menu-nav__about a').addClass('menu-nav__black');
            $('.menu-nav__proposal a').addClass('menu-nav__black');
            $('.menu__logo img').attr('src', './images/icon/logo-mini2.png');
            $('.menu__proposal img').attr('src', './images/icon/left-spec2.png');
            $('.menu__chat img').attr('src', './images/icon/chat2.png');
            $('.menu__instagram ').addClass('instagram-black');
            $('.menu-span').addClass('menu-span-black');
        } else {
            $('.menu__close').removeClass('menu__close_active');
            $('.menu').removeClass('menu-white');
            $('.menu__span-1').removeClass('black-span');
            $('.menu__span-2').removeClass('black-span');
            $('.menu__span-3').removeClass('black-span');
            $('.menu__span-4').removeClass('black-span');
            $('.menu__span-5').removeClass('black-span');
            $('.menu__span-6').removeClass('black-span');
            $('.menu-nav').css({
                'background': 'none'
            });
            $('.menu-nav__link').removeClass('menu-nav__link-black');
            $('.menu-nav__course a').removeClass('menu-nav__black');
            $('.menu-nav__about a').removeClass('menu-nav__black');
            $('.menu-nav__proposal a').removeClass('menu-nav__black');
            $('.menu__logo img').attr('src', './images/icon/logo-mini.png');
            $('.menu__proposal img').attr('src', './images/icon/left-spec1.png');
            $('.menu__chat img').attr('src', './images/icon/chat1.png');
            $('.menu__instagram ').removeClass('instagram-black');
            $('.menu-span').removeClass('menu-span-black');
        }

        if ($(this).scrollTop() != 0) {
            $('.button-up').fadeIn();
        } else {
            $('.button-up').fadeOut();
        }
    });

    $(".button-1").click(function () {
        $(".button-1").removeClass("active-button");
        $(this).toggleClass("active-button");
    });
    $(".button-2").click(function () {
        $(".button-2").removeClass("active-button-2");
        $(this).toggleClass("active-button-2");
    });
    $(".button-3").click(function () {
        $(".button-3").removeClass("active-button-3");
        $(this).toggleClass("active-button-3");
    });
    $(".button-4").click(function () {
        $(".button-4").removeClass("active-button-4");
        $(this).toggleClass("active-button-4");
    });

    $('.button-up').click(function () {
        $('body,html').animate({ scrollTop: 0 }, 800);
    });

    $(".slider ul li").css({
        'width': $('.block__image_wrap').width()
    });
    $(".slider-2 ul li").css({
        'width': $('.block__image_wrap').width()
    });
    $(".slider-3 ul li").css({
        'width': $('.block__image_wrap').width()
    });
    $(".slider-4 ul li").css({
        'width': $('.block__image_wrap').width()
    });
    $(".slider-5 ul li").css({
        'width': $('.block__image_wrap').width()
    });
    $(".slider-6 ul li").css({
        'width': $('.block__image_wrap').width()
    });

    function sliderMove() {
        var slideCount = $(".slider ul li").length;
        var slideWidth = $(".slider ul li").width();
        var slideHeight = $(".slider ul li").height();
        var slideUlWidth = slideCount * slideWidth;

        $(".slider").css({ "width": slideWidth, "height": slideHeight });
        $(".slider ul").css({ "width": slideUlWidth, "margin-left": -slideWidth });
        $(".slider ul li:last-child").prependTo($(".slider ul"));

        function moveLeft() {
            $(".slider ul").stop().animate({
                left: +slideWidth
            }, 700, function () {
                $(".slider ul li:last-child").prependTo($(".slider ul"));
                $(".slider ul").css("left", "");
            });
        }

        function moveRight() {
            $(".slider ul").stop().animate({
                left: -slideWidth
            }, 700, function () {
                $(".slider ul li:first-child").appendTo($(".slider ul"));
                $(".slider ul").css("left", "");
            });
        }

        $(".next").click(function () {
            moveRight();
        });
        $(".prev").click(function () {
            moveLeft();
        });
    }

    function sliderMove_2() {
        var slideCount = $(".slider-2 ul li").length;
        var slideWidth = $(".slider-2 ul li").width();
        var slideHeight = $(".slider-2 ul li").height();
        var slideUlWidth = slideCount * slideWidth;

        $(".slider-2").css({ "width": slideWidth, "height": slideHeight });
        $(".slider-2 ul").css({ "width": slideUlWidth, "margin-left": -slideWidth });
        $(".slider-2 ul li:last-child").prependTo($(".slider-2 ul"));

        function moveLeft() {
            $(".slider-2 ul").stop().animate({
                left: +slideWidth
            }, 700, function () {
                $(".slider-2 ul li:last-child").prependTo($(".slider-2 ul"));
                $(".slider-2 ul").css("left", "");
            });
        }

        function moveRight() {
            $(".slider-2 ul").stop().animate({
                left: -slideWidth
            }, 700, function () {
                $(".slider-2 ul li:first-child").appendTo($(".slider-2 ul"));
                $(".slider-2 ul").css("left", "");
            });
        }

        $(".next-2").click(function () {
            moveRight();
        });
        $(".prev-2").click(function () {
            moveLeft();
        });
    }

    function sliderMove_3() {
        var slideCount = $(".slider-3 ul li").length;
        var slideWidth = $(".slider-3 ul li").width();
        var slideHeight = $(".slider-3 ul li").height();
        var slideUlWidth = slideCount * slideWidth;

        $(".slider-3").css({ "width": slideWidth, "height": slideHeight });
        $(".slider-3 ul").css({ "width": slideUlWidth, "margin-left": -slideWidth });
        $(".slider-3 ul li:last-child").prependTo($(".slider-3 ul"));

        function moveLeft() {
            $(".slider-3 ul").stop().animate({
                left: +slideWidth
            }, 700, function () {
                $(".slider-3 ul li:last-child").prependTo($(".slider-3 ul"));
                $(".slider-3 ul").css("left", "");
            });
        }

        function moveRight() {
            $(".slider-3 ul").stop().animate({
                left: -slideWidth
            }, 700, function () {
                $(".slider-3 ul li:first-child").appendTo($(".slider-3 ul"));
                $(".slider-3 ul").css("left", "");
            });
        }

        $(".next-3").click(function () {
            moveRight();
        });
        $(".prev-3").click(function () {
            moveLeft();
        });
    }

    function sliderMove_4() {
        var slideCount = $(".slider-4 ul li").length;
        var slideWidth = $(".slider-4 ul li").width();
        var slideHeight = $(".slider-4 ul li").height();
        var slideUlWidth = slideCount * slideWidth;

        $(".slider-4").css({ "width": slideWidth, "height": slideHeight });
        $(".slider-4 ul").css({ "width": slideUlWidth, "margin-left": -slideWidth });
        $(".slider-4 ul li:last-child").prependTo($(".slider-4 ul"));

        function moveLeft() {
            $(".slider-4 ul").stop().animate({
                left: +slideWidth
            }, 700, function () {
                $(".slider-4 ul li:last-child").prependTo($(".slider-4 ul"));
                $(".slider-4 ul").css("left", "");
            });
        }

        function moveRight() {
            $(".slider-4 ul").stop().animate({
                left: -slideWidth
            }, 700, function () {
                $(".slider-4 ul li:first-child").appendTo($(".slider-4 ul"));
                $(".slider-4 ul").css("left", "");
            });
        }

        $(".next-4").click(function () {
            moveRight();
        });
        $(".prev-4").click(function () {
            moveLeft();
        });
    }

    function sliderMove_5() {
        var slideCount = $(".slider-5 ul li").length;
        var slideWidth = $(".slider-5 ul li").width();
        var slideHeight = $(".slider-5 ul li").height();
        var slideUlWidth = slideCount * slideWidth;

        $(".slider-5").css({ "width": slideWidth, "height": slideHeight });
        $(".slider-5 ul").css({ "width": slideUlWidth, "margin-left": -slideWidth });
        $(".slider-5 ul li:last-child").prependTo($(".slider-5 ul"));

        function moveLeft() {
            $(".slider-5 ul").stop().animate({
                left: +slideWidth
            }, 700, function () {
                $(".slider-5 ul li:last-child").prependTo($(".slider-5 ul"));
                $(".slider-5 ul").css("left", "");
            });
        }

        function moveRight() {
            $(".slider-5 ul").stop().animate({
                left: -slideWidth
            }, 700, function () {
                $(".slider-5 ul li:first-child").appendTo($(".slider-5 ul"));
                $(".slider-5 ul").css("left", "");
            });
        }

        $(".next-5").click(function () {
            moveRight();
        });
        $(".prev-5").click(function () {
            moveLeft();
        });
    }

    function sliderMove_6() {
        var slideCount = $(".slider-6 ul li").length;
        var slideWidth = $(".slider-6 ul li").width();
        var slideHeight = $(".slider-6 ul li").height();
        var slideUlWidth = slideCount * slideWidth;

        $(".slider-6").css({ "width": slideWidth, "height": slideHeight });
        $(".slider-6 ul").css({ "width": slideUlWidth, "margin-left": -slideWidth });
        $(".slider-6 ul li:last-child").prependTo($(".slider-6 ul"));

        function moveLeft() {
            $(".slider-6 ul").stop().animate({
                left: +slideWidth
            }, 700, function () {
                $(".slider-6 ul li:last-child").prependTo($(".slider-6 ul"));
                $(".slider-6 ul").css("left", "");
            });
        }

        function moveRight() {
            $(".slider-6 ul").stop().animate({
                left: -slideWidth
            }, 700, function () {
                $(".slider-6 ul li:first-child").appendTo($(".slider-6 ul"));
                $(".slider-6 ul").css("left", "");
            });
        }

        $(".next-6").click(function () {
            moveRight();
        });
        $(".prev-6").click(function () {
            moveLeft();
        });
    }

    function mainSlider() {
        var owl = $('.owl-carousel');

        owl.owlCarousel({
            loop: true,
            smartSpeed: 1600,
            nav: true,
            dots: false,
            margin: 50,
            responsiveClass: true,
            autoplay: true,
            autoplayHoverPause: true,
            responsive: {
                0: {
                    items: 1
                },
                600: {
                    items: 2
                },
                1000: {
                    items: 3
                }
            }
        });
    }

    sliderMove();
    sliderMove_2();
    sliderMove_3();
    sliderMove_4();
    sliderMove_5();
    sliderMove_6();
    mainSlider();
});